import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { AutoContainer } from '../components/common'

const PrivacyWrapper = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  font-size: 18px;

  h2 {
    font-weight: 600;
    font-size: 28px;
    padding-bottom: 10px;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    padding-bottom: 10px;
  }

  h4 {
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 10px;
  }

  h5 {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px;
  }

  ul {
    padding-left: 60px;
    padding-bottom: 10px;

    li {
      list-style: outside;
    }
  }

  span {
    display: block;
    padding-bottom: 20px;
  }
`

const Privacy = () => (
  <AutoContainer>
    <PrivacyWrapper>
      <span>Last updated: May 26, 2020</span>
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You. We use Your Personal data to provide and improve the Service. By
        using the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>
      <h2>Interpretation and Definitions</h2>
      <h3>Interpretation</h3>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3>Definitions</h3>
      <p>For the purposes of this Privacy Policy:</p>
      <ul>
        <li>
          You means the individual accessing or using the Service, or the
          company, or other legal entity on behalf of which such individual is
          accessing or using the Service, as applicable.
        </li>
        <li>
          Company (referred to as either "the Company", "We", "Us" or "Our" in
          this Agreement) refers to Kaira Luchi LLC, Ellicott City.
        </li>
        <li>
          Affiliate means an entity that controls, is controlled by or is under
          common control with a party, where "control" means ownership of 50% or
          more of the shares, equity interest or other securities entitled to
          vote for election of directors or other managing authority.
        </li>
        <li>
          Account means a unique account created for You to access Our Service
          or parts of Our Service.
        </li>
        <li>
          Website refers to Kaira Luchi, accessible from
          https://www.kairaluchi.com
        </li>
        <li>Service refers to the Website.</li>
        <li>Country refers to: Maryland, United States</li>
        <li>
          Service Provider means any natural or legal person who processes the
          data on behalf of the Company. It refers to third-party companies or
          individuals employed by the Company to facilitate the Service, to
          provide the Service on behalf of the Company, to perform services
          related to the Service or to assist the Company in analyzing how the
          Service is used.
        </li>
        <li>
          Third-party Social Media Service refers to any website or any social
          network website through which a User can log in or create an account
          to use the Service.
        </li>
        <li>
          Personal Data is any information that relates to an identified or
          identifiable individual.
        </li>
        <li>
          Cookies are small files that are placed on Your computer, mobile
          device or any other device by a website, containing the details of
          Your browsing history on that website among its many uses.
        </li>
        <li>
          Device means any device that can access the Service such as a
          computer, a cellphone or a digital tablet.
        </li>
        <li>
          Usage Data refers to data collected automatically, either generated by
          the use of the Service or from the Service infrastructure itself (for
          example, the duration of a page visit).
        </li>
      </ul>
      <h3>Collecting and Using Your Personal Data</h3>
      <h4>Types of Data Collected</h4>
      <h5>Personal Data</h5>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information that can be used to contact or
        identify You. Personally identifiable information may include, but is
        not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Reason for contacting the Company</li>
      </ul>
      <h5>Use of Your Personal Data</h5>
      <p>The Company may use Personal Data for the following purposes:</p>
      <ul>
        <li>
          To contact You: To contact You by email, telephone calls, SMS, or
          other equivalent forms of electronic communication, such as a mobile
          application's push notifications regarding updates or informative
          communications related to the functionalities, products or contracted
          services, including the security updates, when necessary or reasonable
          for their implementation.
        </li>
        <li>
          To provide You with news, special offers and general information about
          other goods, services and events which We offer that are similar to
          those that you have already purchased or enquired about unless You
          have opted not to receive such information.
        </li>
        <li>
          To manage Your requests: To attend and manage Your requests to Us.
        </li>
      </ul>
      <p>We may share Your personal information in the following situations:</p>
      <ul>
        <li>
          For Business transfers: We may share or transfer Your personal
          information in connection with, or during negotiations of, any merger,
          sale of Company assets, financing, or acquisition of all or a portion
          of Our business to another company.
        </li>
        <li>
          With Affiliates: We may share Your information with Our affiliates, in
          which case We will require those affiliates to honor this Privacy
          Policy. Affiliates include Our parent company and any other
          subsidiaries, joint venture partners or other companies that We
          control or that are under common control with Us.
        </li>
        <li>
          With Business partners: We may share Your information with Our
          business partners to offer You certain products, services or
          promotions.
        </li>
        <li>
          With other users: When You share personal information or otherwise
          interact in the public areas with other users, such information may be
          viewed by all users and may be publicly distributed outside. If You
          interact with other users or register through a Third-Party Social
          Media Service, Your contacts on the Third-Party Social Media Service
          may see Your name, profile, pictures and description of Your activity.
          Similarly, other users will be able to view descriptions of Your
          activity, communicate with You and view Your profile.
        </li>
      </ul>
      <h3>Social Networking</h3>
      <p>
        The Sites may allow you to sign into and associate Your social network
        accounts including, but not limited to, Twitter, LinkedIn, Facebook,
        with the Company.
      </p>
      <h3>Retention of Your Personal Data</h3>
      <p>
        The Company will retain Your Personal Data only for as long as is
        necessary for the purposes set out in this Privacy Policy. We will
        retain and use Your Personal Data to the extent necessary to comply with
        Our legal obligations (for example, if We are required to retain Your
        data to comply with applicable laws), resolve disputes, and enforce Our
        legal agreements and policies.
      </p>
      <h3>Transfer of Your Personal Data</h3>
      <p>
        Your information, including Personal Data, is processed at the Company's
        operating offices and in any other places where the parties involved in
        the processing are located. It means that this information may be
        transferred to — and maintained on — computers located outside of Your
        state, province, country or other governmental jurisdiction where the
        data protection laws may differ than those from Your jurisdiction.
      </p>
      <p>
        Your consent to this Privacy Policy followed by Your submission of such
        information represents Your agreement to that transfer.
      </p>
      <p>
        The Company will take all steps reasonably necessary to ensure that Your
        data is treated securely and in accordance with this Privacy Policy and
        no transfer of Your Personal Data will take place to an organization or
        a country unless there are adequate controls in place including the
        security of Your data and other personal information.
      </p>
      <h3>Disclosure of Your Personal Data</h3>
      <h4>Business Transactions</h4>
      <p>
        If the Company is involved in a merger, acquisition or asset sale, Your
        Personal Data may be transferred. We will provide notice before Your
        Personal Data is transferred and becomes subject to a different Privacy
        Policy.
      </p>
      <h4>Law enforcement</h4>
      <p>
        Under certain circumstances, the Company may be required to disclose
        Your Personal Data if required to do so by law or in response to valid
        requests by public authorities (e.g. a court or a government agency).
      </p>
      <h4>Other legal requirements</h4>
      <p>
        The Company may disclose Your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul>
        <li>Comply with a legal obligation</li>
        <li>Protect and defend the rights or property of the Company</li>
        <li>
          Prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li>
          Protect the personal safety of Users of the Service or the public
        </li>
        <li>Protect against legal liability</li>
      </ul>
      <h3>Security of Your Personal Data</h3>
      <p>
        The security of Your Personal Data is important to Us, but remember that
        no method of transmission over the Internet, or method of electronic
        storage is 100% secure. While We strive to use commercially acceptable
        means to protect Your Personal Data, We cannot guarantee its absolute
        security.
      </p>
      <h3>Unsubscribe</h3>
      <p>
        If you have registered for Our newsletter or alerts, or you receive
        invitations to survey or events from Us and you prefer not to receive
        future email communications from Us, please follow the instructions at
        the bottom of Our emails to you, to unsubscribe from future emails from
        Us.
      </p>
      <h3>Your California Privacy Rights (California's Shine the Light law)</h3>
      <p>
        Under California Civil Code Section 1798 (California's Shine the Light
        law), California residents with an established business relationship
        with Us can request information once a year about sharing their Personal
        Data with third parties for the third parties' direct marketing
        purposes. If you'd like to request more information under the California
        Shine the Light law, and if you are a California resident, You can
        contact Us using the contact information provided below.
      </p>
      <h3>
        California Privacy Rights for Minor Users (California Business and
        Professions Code Section 22581)
      </h3>
      California Business and Professions Code section 22581 allow California
      residents under the age of 18 who are registered users of online sites,
      services or applications to request and obtain removal of content or
      information they have publicly posted. To request removal of such data,
      and if you are a California resident, You can contact Us using the contact
      information provided below, and include the email address associated with
      Your account. Be aware that Your request does not guarantee complete or
      comprehensive removal of content or information posted online and that the
      law may not permit or require removal in certain circumstances.
      <h3>Children</h3>
      <p>
        We understand the importance of protecting children's privacy,
        particularly in their online interactions. This Site is not designed for
        and does not intentionally target or solicit to children 18 years of age
        and younger.
      </p>
      <h3>Links to Other Websites</h3>
      <p>
        Our Service may contain links to other websites that are not operated by
        Us. If You click on a third party link, You will be directed to that
        third party's site. We strongly advise You to review the Privacy Policy
        of every site You visit. We have no control over and assume no
        responsibility for the content, privacy policies or practices of any
        third party sites or services. Changes to this Privacy Policy We may
        update Our Privacy Policy from time to time. We will notify You of any
        changes by posting the new Privacy Policy on this page. We will let You
        know via email and/or a prominent notice on Our Service, prior to the
        change becoming effective and update the "Last updated" date at the top
        of this Privacy Policy. You are advised to review this Privacy Policy
        periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
      </p>
      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, You can contact Us:
      </p>
      <ul>
        <li>By email: info@kairaluchi.com</li>
      </ul>
    </PrivacyWrapper>
  </AutoContainer>
)

const PrivacyPage = props => (
  <Layout page='Privacy Policy' {...props}>
    <Seo title='Privacy Policy' description='privacy policy' />
    <Privacy />
  </Layout>
)

export default PrivacyPage
